<template>
  <div class="mentionsLegales" >
    <formTitleComponent />
    <div class="container">
      <h1 class="form_div_title text-center">Mentions légales</h1>
      <div class="form_div">
        <div class="form_content">
          <div class="row">
            <div class="col-12">
              <p>
                Ce site est édité par :
              </p>
              <p>
                <strong> Dénomination sociale : </strong> <span>Geegz</span><br>
              </p>
              <p>
                <strong> RCS : </strong> <span>883 531 105 R.C.S Paris</span><br>
              </p>
              <p>
                <strong> Représentant légal : </strong><span> Guillaume Guerdoux </span><br>
              </p>
              <p>
                <strong> Activité : </strong> <span>Programmation informatique, création et gestion de plateformes d'intermédiation </span><br>
              </p>
              <p>
                <strong> Adresse du siège : </strong> <span>18 passage du chantier, 75012 Paris </span><br>
              </p>
              <p>
                <strong> Directeur de publication : </strong> <span>Guillaume Guerdoux, Président, guillaume.guerdoux@geegz.fr</span><br>
              </p>
              <p>
                <strong> Hébergeur : </strong> <span>OVH SAS, 424 761 419 R.C.S Lille, 2 rue Kellermann - 59100 Roubaix - France</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const formTitleComponent = () => import('@/components/formTitleComponent')

export default {
  name: 'MentionsLegales',
  components: {
    formTitleComponent,
  },
}
</script>
